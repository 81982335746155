
import { defineComponent, ref } from 'vue';
import { erpConfirm, errorMessage, exportFile } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';

export default defineComponent({
  name: 'ExportTable',
  components: {},
  props: {},
  setup() {
    const validate = ref(false);
    const filterLen = ref(0);
    const tag = ref('日期');
    const formData = ref({
      dateRange: [],
      startDate: '',
      endDate: '',
    });
    const { startTimer, timeStatus } = useTimer();
    const visible = ref(false);

    const closeVisible = () => {
      visible.value = false;
    };

    const changeDate = () => {
      if (formData.value.dateRange) {
        validate.value = false;
      } else {
        validate.value = true;
      }
    };

    const getQueryParams = () => {
      if (formData.value.dateRange?.length > 0) {
        formData.value.startDate = formData.value.dateRange[0];
        formData.value.endDate = formData.value.dateRange[1];
        filterLen.value = 1;
      } else {
        validate.value = true;
        return;
      }
      closeVisible();
    };

    /** 重置参数方法 */
    const getRestParams = () => {
      formData.value.startDate = '';
      formData.value.endDate = '';
      formData.value.dateRange = [];
      filterLen.value = 0;
      validate.value = false;
      closeVisible();
    };

    const exportReport = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/order/exportAllOrderMessage',
            data: formData.value,
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    return {
      getRestParams,
      getQueryParams,
      exportReport,
      closeVisible,
      changeDate,
      filterLen,
      tag,
      validate,
      visible,
      formData,
    };
  },
});
